import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Img from "gatsby-image"

// Images
import ogImageSrc from "../assets/og.jpg"

export default ({ data }) => {
  console.log(data.file.childImageSharp.fluid)
  return (
    <>
      <Helmet>
        <title>Sarah Hanna</title>
        <meta
          name="description"
          content="Creating helpful and beautiful visual experiences that keep people engaged and make information easy to quickly understand."
        />
        <meta property="og:title" content="Sarah Hanna" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sarahhanna.co" />
        <meta
          property="og:description"
          content="Creating helpful and beautiful visual experiences that keep people engaged and make information easy to quickly understand."
        ></meta>
        <meta property="og:image" content={ogImageSrc} />
      </Helmet>
      <GlobalStyles />
      <Wrapper>
        <Polaroid>
          <Img fluid={data.file.childImageSharp.fluid} alt="Sarah Hanna" />
        </Polaroid>
        <Divider />
        <Details>
          <h1>Designer & Co-Founder @SuperChill</h1>
          <p>
          As I've narrowed my focus to product design, I have enjoyed seeing the possibilities of where this work can take me and the unlimited opportunities for continuous learning. I&apos;ve had the opportunity to work in a variety of industries and different types of companies over the years from nonprofits to corporate companies and my favorite projects to work on are those that let me work with people who are passionate about doing great work but who also don&apos;t take themselves too seriously. My goal in my work is to create helpful and beautiful experiences that keep people engaged and make information and systems easy to quickly understand.
          </p>
          <div className="links">
            <div className="social-links">
              <a
                className="linkedin"
                href="https://www.linkedin.com/in/thesarahhanna/"
              >
                LinkedIn
              </a>
              <Divider small className="social-divider" />
              <a
                className="instagram"
                href="https://www.instagram.com/thesarahhanna/"
              >
                Instagram
              </a>
              <Divider small className="social-divider" />
              <a className="dribbble" href="https://dribbble.com/thesarahhanna">
                Dribbble
              </a>
            </div>
            <Divider small />
            <a
              className="portfolio"
              href="https://www.figma.com/proto/gIKrrESUxV3wvmUnubuEI4/Sarah-Hanna--Resume-%26-Portfolio?page-id=1%3A47&node-id=1-2589&viewport=-1388%2C-2573%2C0.5&scaling=scale-down-width&starting-point-node-id=1%3A2589"
            >
              View my portfolio
            </a>
            <Divider small />
            <a className="email" href="mailto:sarahjhanna@gmail.com">
              Let's chat - email me here!
            </a>
          </div>
        </Details>
      </Wrapper>
    </>
  )
}

// Image Query
export const query = graphql`
  query {
    file(relativePath: { eq: "sarah.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

// Styles
const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');
  
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', san-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1rem;
    color: #2b2a28;
  }
`

const Wrapper = styled.div`
  display: flex;
  max-width: 960px;
  margin: 220px auto 0;

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    max-width: 548px;
    margin-top: 96px;
  }

  @media (max-width: 580px) {
    margin: 0;
    padding: 32px;
  }
`

const Polaroid = styled.div`
  width: 100%;
  max-width: 375px;

  @media (max-width: 992px) {
    flex: auto;
    margin-bottom: 32px;
    width: 346px;
  }

  @media (max-width: 580px) {
    width: 100%;
    margin-bottom: 24px;
  }
`

const Divider = styled.div`
  width: 2px;
  min-height: 100%;
  margin: 0 ${props => (props.small ? "0" : "20px")};
  background: #eeeeee;

  &.social-divider {
    margin: 0 4px;
    background: transparent;
  }

  @media (max-width: 580px) {
    display: none;

    &.social-divider {
      display: block;
      margin: 0;
      background: #eeeeee;
    }
  }
`

const Details = styled.div`
  flex: 1;
  padding-top: 8px;
  min-height: 100%;

  h1 {
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
  }

  p {
    line-height: 1.3;
  }

  .links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 16px;
    border: 2px solid #eeeeee;

    .social-links {
      display: flex;
    }

    a {
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;

        &.no-decoration {
          text-decoration: none;
        }
      }

      &.linkedin {
        color: #1178b3;
      }

      &.instagram {
        background: linear-gradient(
          to right,
          #5458c4,
          #6457bf,
          #ac36a0,
          #ce3279,
          #e75d49,
          #f1944f,
          #f5c46c,
          #c94f60,
          #a63788
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-decoration-color: #a63788;
      }

      &.dribbble {
        color: #e84f89;
      }

      &.portfolio {
        color: #e75d49;
        font-weight: 700;
      }

      &.email {
        color: #40779f;
      }
    }
  }

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 580px) {
    p {
      text-align: left;
    }

    .links {
      flex-direction: column;
      align-items: center;
      border: none;
      padding: 0;

      .social-links {
        width: 100%;
        justify-content: space-around;
        border: 1px solid #eeeeee;
        padding: 16px;
      }

      a {
        &.portfolio,
        &.email {
          width: 100%;
          padding: 16px;
          border: 1px solid #eeeeee;
        }
      }

      > * {
        margin-bottom: 8px;
      }
    }
  }
`
